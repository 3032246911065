import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import QuestionPage from "./Question";
import StartForm from "./StartForm";
import SummaryScore from "./SummaryScore";

const SafetyExamination = () => {
  const navigate = useNavigate();
  const [staffId, setStaffId] = useState<number | null>(null);
  const [examId, setExamId] = useState<number | null>(null);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const handleGotoExam = (staffId: number, examId: number) => {
    setStaffId(staffId);
    setExamId(examId);
    setIsFinished(false);
  };

  const handleGotoResult = () => {
    setIsFinished(true);
  };

  const handleReset = () => {
    setStaffId(null);
    setExamId(null);
    setIsFinished(false);
    navigate("/request-process-2");
  };

  if (staffId && examId) {
    if (isFinished) {
      return (
        <SummaryScore
          examId={examId}
          staffId={staffId}
          gotoExam={handleGotoExam}
          gotoNext={handleReset}
        />
      );
    }
    return (
      <QuestionPage
        examId={examId}
        staffId={staffId}
        gotoResult={handleGotoResult}
      />
    );
  }
  return <StartForm gotoExam={handleGotoExam} />;
};

export default SafetyExamination;
