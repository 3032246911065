import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguage } from "../../providers/language";
import { Language } from "../../schemas/language";
import constructSvg from "../../assets/icons/icon-20.svg";
import marketingSvg from "../../assets/icons/icon-21.svg";
import cardIdSvg from "../../assets/icons/icon-12.svg";
import claimDocSvg from "../../assets/icons/icon-17.svg";
import questionSvg from "../../assets/icons/icon-22.svg";

import { useNavigate } from "react-router-dom";

function Documents() {
  const navigate = useNavigate();
  const { language, changeLanguage } = useLanguage();

  return (
    <>
      <div className="w-full bg-white">
        <div className="flex flex-wrap pt-4 justify-center">
          <div
            className="m-6 p-3 border border-black rounded-[2rem] w-[20rem] h-[20rem] shadow-[10px_10px] cursor-pointer"
            onClick={() => navigate("/forms/work_permit")}
          >
            <div className="flex justify-center">
              <img className="h-[13rem]" src={constructSvg} alt="forms" />
            </div>
            <div className="flex justify-center items-center h-[4rem] px-3 font-medium">
              <div className="text-xl text-center">
                {language === Language.TH
                  ? "แบบฟอร์มขออนุญาตเข้าทำงานและตกแต่งพื้นที่"
                  : "Work Permit and Construction Requirement"}
              </div>
            </div>
          </div>

          <div
            className="m-6 p-3 border border-black rounded-[2rem] w-[20rem] h-[20rem] shadow-[10px_10px] cursor-pointer"
            onClick={() => navigate("/forms/marketing")}
          >
            <div className="flex justify-center">
              <img className="h-[13rem]" src={marketingSvg} alt="forms" />
            </div>
            <div className="flex justify-center items-center h-[4rem] px-3 font-medium">
              <div className="text-xl text-center">
                {language === Language.TH
                  ? "แบบฟอร์มการตลาด"
                  : "Marketing Form"}
              </div>
            </div>
          </div>

          <div
            className="flex-row item-center m-6 p-3 border border-black rounded-[2rem] w-[20rem] h-[20rem] shadow-[10px_10px] cursor-pointer"
            onClick={() => navigate("/forms/staff_and_parking")}
          >
            <div className="flex justify-center">
              <img className="h-[13rem]" src={cardIdSvg} alt="forms" />
            </div>
            <div className="flex justify-center items-center h-[4rem] px-3 font-medium">
              <div className="text-xl text-center">
                {language === Language.TH
                  ? "แบบฟอร์มบัตรประจำตัวพนักงานและบัตรจอดรถ"
                  : "Staff ID Card and Parking Card"}
              </div>
            </div>
          </div>

          <div
            className="m-6 p-3 border border-black rounded-[2rem] w-[20rem] h-[20rem] shadow-[10px_10px] cursor-pointer"
            onClick={() => navigate("/forms/claim_application")}
          >
            <div className="flex justify-center">
              <img className="h-[13rem]" src={claimDocSvg} alt="forms" />
            </div>
            <div className="flex justify-center items-center h-[4rem] px-3 font-medium">
              <div className="text-xl text-center">
                {language === Language.TH
                  ? "แบบฟอร์มการแจ้งความเสียหาย"
                  : "Claim Application"}
              </div>
            </div>
          </div>

          <div
            className="m-6 p-3 border border-black rounded-[2rem] w-[20rem] h-[20rem] shadow-[10px_10px] cursor-pointer"
            onClick={() => navigate("/forms/other_forms")}
          >
            <div className="flex justify-center">
              <img className="h-[13rem]" src={questionSvg} alt="forms" />
            </div>
            <div className="flex justify-center items-center h-[4rem] px-3 font-medium">
              <div className="text-xl text-center">
                {language === Language.TH ? "แบบฟอร์ม อื่นๆ" : "Form Etc."}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Documents;
