import React, { useState, createContext, ReactNode, useContext } from "react";
import { Language } from "../../schemas/language";

interface languageContextInterface {
  language: Language;
  changeLanguage: (language: Language) => void;
}

const languageContext = createContext<languageContextInterface>({
  language: Language.TH,
  changeLanguage: (language: Language) => {},
});

export const useProvideLanguage = () => {
  const [language, setLanguage] = useState<Language>(Language.TH);

  const changeLanguage = (language: Language) => {
    setLanguage(language);
  };

  return {
    language,
    changeLanguage,
  };
};

export const useLanguage = () => {
  return useContext(languageContext);
};

export const ProvideLanguage = ({ children }: { children: ReactNode }) => {
  const language = useProvideLanguage();

  return (
    <languageContext.Provider value={language}>
      {children}
    </languageContext.Provider>
  );
};
