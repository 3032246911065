import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
api.defaults.headers.common[
  "Authorization"
] = `Token ${process.env.REACT_APP_API_TOKEN}`;

export { api };
