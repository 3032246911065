import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguage } from "../../providers/language";
import { Language } from "../../schemas/language";
import userSvg from "../../assets/icons/1-black.svg";
import wwwSvg from "../../assets/icons/3-black.svg";
import userReadSvg from "../../assets/icons/4-black.svg";
import menuList from "../../assets/icons/6-black.svg";
import menuCoinList from "../../assets/icons/7-black.svg";
import userSigning from "../../assets/icons/8-black.svg";
import camera from "../../assets/icons/9-black.svg";
import { Link, useNavigate } from "react-router-dom";

function RequestProcess() {
  const navigate = useNavigate();
  const { language, changeLanguage } = useLanguage();

  return (
    <>
      <div className="w-full bg-white">
        <div className="flex pt-4 justify-center items-center">
          <div className="mr-2">
            <img
              className="relative top-[4px] w-[100px] inline"
              src={userSvg}
              alt="userSvg"
            />
          </div>
          <div className="text-2xl font-bold">
            <div>ขั้นตอนการทำบัตรประจำตัวพนักงานร้านค้า</div>
            <div className="text-xl font-bold">TENANT CARD REQUEST PROCESS</div>
          </div>
        </div>
        <div className="mt-5 px-10 grid gap-10">
          <div className="px-4 relative grid grid-cols-3">
            <div className="absolute top w-full h-[60px] bg-[#dee0e9] rounded-full"></div>
            <div className="relative">
              <div className="flex justify-evenly h-[60px]">
                <div
                  className="
                  relative
                  flex 
                  items-center 
                  justify-center 
                  text-center 
                  w-[60px] h-[60px]
                  border-4 rounded-full
                  border-neutral-900
                  border-dashed
                "
                >
                  <span className="text-2xl font-bold">01</span>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-38px",
                      left: "8px",
                      display: "inline-block",
                      backgroundImage: `url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><polyline points='0,20 20,0 40,20'  fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linejoin='miter'/></svg>")`,
                      width: "40px",
                      height: "20px",
                      // transform: "rotate(-45deg)",
                      borderBottom: "2px solid white",
                      zIndex: 1,
                    }}
                  />
                </div>
                <div className="px-4relative flex justify-center items-center z-10">
                  <img
                    className="relative top-[4px] w-[80px] inline"
                    src={wwwSvg}
                    alt="www"
                  />
                </div>
              </div>
              <div
                className="overflow-hidden relative h-[100px] p-5 mt-8 flex items-center"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
                  borderRadius: "12px",
                  backgroundColor: "white",
                }}
              >
                <p className="text-center">
                  {language === Language.TH
                    ? "เข้า WEBSITE MEGABANGNA หรือติดต่อ RECEPTION เพื่อขอทำบัตร"
                    : "Access into MEGABANGNA Shopping Center website or contact Reception Counter (Megabangna Office)"}
                </p>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "91px",
                  left: "-8px",
                  width: "8px",
                  height: "100px",
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              />
            </div>
            <div className="relative">
              <div className="flex justify-evenly h-[60px]">
                <div
                  className="
                  relative
                  flex 
                  items-center 
                  justify-center 
                  text-center 
                  w-[60px] h-[60px]
                  border-4 rounded-full
                  border-[#e92323]
                  border-dashed
                "
                >
                  <span className="text-2xl font-bold">02</span>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-38px",
                      left: "8px",
                      display: "inline-block",
                      backgroundImage: `url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><polyline points='0,20 20,0 40,20'  fill='none' stroke='%23e92323' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linejoin='miter'/></svg>")`,
                      width: "40px",
                      height: "20px",
                      // transform: "rotate(-45deg)",
                      borderBottom: "2px solid white",
                      zIndex: 1,
                    }}
                  />
                </div>
                <div className="px-4relative flex justify-center items-center z-10">
                  <img
                    className="relative top-[4px] w-[80px] inline"
                    src={userReadSvg}
                    alt="userReadSvg"
                  />
                </div>
              </div>
              <div
                className="overflow-hidden relative h-[100px] p-5 mt-8 flex items-center justify-center"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23e92323' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
                  borderRadius: "12px",
                  backgroundColor: "white",
                }}
              >
                <div className="text-center">
                  {language === Language.TH
                    ? "อ่านคู่มือมาตราการความปลอดภัย"
                    : "Learn Safety Manual"}
                  <br /> -{" "}
                  <Link className="underline" to="/forms">
                    {language === Language.TH ? "กดที่นี่" : "Click Here"}
                  </Link>{" "}
                  -
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "91px",
                  left: "-8px",
                  width: "8px",
                  height: "100px",
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              />
            </div>
            <div className="relative">
              <div className="flex justify-evenly h-[60px]">
                <div
                  className="
                  relative
                  flex 
                  items-center 
                  justify-center 
                  text-center 
                  w-[60px] h-[60px]
                  border-4 rounded-full
                  border-neutral-900
                  border-dashed
                "
                >
                  <span className="text-2xl font-bold">03</span>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-38px",
                      left: "8px",
                      display: "inline-block",
                      backgroundImage: `url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><polyline points='0,20 20,0 40,20'  fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linejoin='miter'/></svg>")`,
                      width: "40px",
                      height: "20px",
                      // transform: "rotate(-45deg)",
                      borderBottom: "2px solid white",
                      zIndex: 1,
                    }}
                  />
                </div>
                <div className="px-4relative flex justify-center items-center z-10">
                  <img
                    className="relative top-[4px] w-[80px] inline"
                    src={menuList}
                    alt="menuList"
                  />
                </div>
              </div>
              <div
                className="overflow-hidden relative h-[100px] p-5 mt-8 flex items-center justify-center"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
                  borderRadius: "12px",
                  backgroundColor: "white",
                }}
              >
                <p className="text-center">
                  {language === Language.TH
                    ? "ทำแบบทดสอบความเข้าใจด้านความปลอดภัยและ มาตราการรักษาความปลอดภัย"
                    : "Take Megabangna Safety Examination"}
                </p>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "91px",
                  left: "-8px",
                  width: "8px",
                  height: "100px",
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              />
            </div>
          </div>

          <div className="px-4 relative grid grid-cols-3">
            <div className="absolute top w-full h-[60px] bg-[#dee0e9] rounded-full"></div>
            {/*  */}
            <div className="relative">
              <div className="flex justify-evenly h-[60px]">
                <div
                  className="
                  relative
                  flex 
                  items-center 
                  justify-center 
                  text-center 
                  w-[60px] h-[60px]
                  border-4 rounded-full
                  border-neutral-900
                  border-dashed
                "
                >
                  <span className="text-2xl font-bold">03</span>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-38px",
                      left: "8px",
                      display: "inline-block",
                      backgroundImage: `url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><polyline points='0,20 20,0 40,20'  fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linejoin='miter'/></svg>")`,
                      width: "40px",
                      height: "20px",
                      // transform: "rotate(-45deg)",
                      borderBottom: "2px solid white",
                      zIndex: 1,
                    }}
                  />
                </div>
                <div className="px-4relative flex justify-center items-center z-10">
                  <img
                    className="relative top-[4px] w-[80px] inline"
                    src={menuCoinList}
                    alt="menuCoinList"
                  />
                </div>
              </div>
              <div
                className="overflow-hidden relative h-[220px] p-5 mt-8 "
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
                  borderRadius: "12px",
                  backgroundColor: "white",
                }}
              >
                {language === Language.TH ? (
                  <>
                    <h1 className="text-lg text-center font-bold">
                      เตรียมเอกสาร เพื่อยื่นขอทำบัตร
                    </h1>
                    <ul className="pl-6 list-outside list-['-_'] mt-2">
                      <li>สำเนาบัตรประชาชน / สำเนาพาสปอร์ต</li>
                      <li>ผลข้อสอบ</li>
                      <li>
                        กรณีต้องการสิทธิจอดรถ ขอสำเนาทะเบียน รถ
                        ที่มีชื่อผู้ครอบครองตรงกับชื่อพนักงาน
                        หรือบุคคลในครอบครัว
                      </li>
                      <li>ค่าทำบัตรใหม่ 50 บาท</li>
                    </ul>
                  </>
                ) : (
                  <>
                    <h1 className="text-lg text-center font-bold">
                      Required Documents
                    </h1>
                    <ul className="pl-6 list-outside list-['-_'] mt-2 text-sm">
                      <li>ID Card Request Form / Copy</li>
                      <li>ID Card or Passport / Safety</li>
                      <li>Examination Result</li>
                      <li>
                        For parking card: a copy of car register indicated staff
                        tenant name or family name as the owner
                      </li>
                      <li>New card fee THB 50.00</li>
                    </ul>
                  </>
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "91px",
                  left: "-8px",
                  width: "8px",
                  height: "220px",
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              />
            </div>

            {/*  */}
            <div className="relative">
              <div className="flex justify-evenly h-[60px]">
                <div
                  className="
                  relative
                  flex 
                  items-center 
                  justify-center 
                  text-center 
                  w-[60px] h-[60px]
                  border-4 rounded-full
                  border-[#e92323]
                  border-dashed
                "
                >
                  <span className="text-2xl font-bold">04</span>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-38px",
                      left: "8px",
                      display: "inline-block",
                      backgroundImage: `url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><polyline points='0,20 20,0 40,20'  fill='none' stroke='%23e92323' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linejoin='miter'/></svg>")`,
                      width: "40px",
                      height: "20px",
                      // transform: "rotate(-45deg)",
                      borderBottom: "2px solid white",
                      zIndex: 1,
                    }}
                  />
                </div>
                <div className="px-4relative flex justify-center items-center z-10">
                  <img
                    className="relative top-[0px] w-[80px] inline"
                    src={userSigning}
                    alt="userSigning"
                  />
                </div>
              </div>
              <div
                className="overflow-hidden relative h-[220px] p-5 mt-8"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23e92323' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
                  borderRadius: "12px",
                  backgroundColor: "white",
                }}
              >
                {language === Language.TH ? (
                  <>
                    <h1 className="text-lg text-center font-bold">
                      ติดต่อ RECEPTION ที่สำนักงานเมกาบางนา
                    </h1>

                    <ul className="pl-6 list-outside list-['-_'] mt-2">
                      <li>ยื่นเอกสาร</li>
                      <li>ชำระเงิน</li>
                      <li>นัดหมายวันถ่ายรูป</li>
                    </ul>
                  </>
                ) : (
                  <>
                    <h1 className="text-lg text-center font-bold">
                      Contact Reception Counter (Megabangna Office)
                    </h1>

                    <ul className="pl-6 list-outside list-['-_'] mt-2">
                      <li>Submit documents</li>
                      <li>Pay a fee</li>
                      <li>Make an appointment</li>
                    </ul>
                  </>
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "91px",
                  left: "-8px",
                  width: "8px",
                  height: "220px",
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              />
            </div>

            {/*  */}
            <div className="relative">
              <div className="flex justify-evenly h-[60px]">
                <div
                  className="
                  relative
                  flex 
                  items-center 
                  justify-center 
                  text-center 
                  w-[60px] h-[60px]
                  border-4 rounded-full
                  border-neutral-900
                  border-dashed
                "
                >
                  <span className="text-2xl font-bold">05</span>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-38px",
                      left: "8px",
                      display: "inline-block",
                      backgroundImage: `url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><polyline points='0,20 20,0 40,20'  fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linejoin='miter'/></svg>")`,
                      width: "40px",
                      height: "20px",
                      // transform: "rotate(-45deg)",
                      borderBottom: "2px solid white",
                      zIndex: 1,
                    }}
                  />
                </div>
                <div className="px-4relative flex justify-center items-center z-10">
                  <img
                    className="relative top-[0px] w-[80px] inline"
                    src={camera}
                    alt="camera"
                  />
                </div>
              </div>
              <div
                className="overflow-hidden relative h-[220px] p-5 mt-8"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
                  borderRadius: "12px",
                  backgroundColor: "white",
                }}
              >
                <h1 className="text-lg text-center font-bold">
                  {language === Language.TH ? "วันนัดหมาย" : "Appointment"}
                </h1>
                <div className="mt-2">
                  <div className="text-center">
                    {language === Language.TH
                      ? "ถ่ายรูปทำบัตร พร้อมรับบัตรทันที"
                      : "Take a photo and receive Tenant Card"}
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="w-[100px]"
                      src={userSvg}
                      alt="userSvgBlack"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "91px",
                  left: "-8px",
                  width: "8px",
                  height: "220px",
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              />
            </div>
          </div>
        </div>
        <div className="py-8 px-10 flex justify-between">
          <div className="flex justify-end items-center">
            {/* <div className="">ถัดไป</div> */}
            <FontAwesomeIcon
              className=""
              icon="angles-left"
              size="2x"
              color="#333"
            />
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-8 rounded-[20px]"
              onClick={() => navigate("/")}
            >
              {language === Language.TH ? "ย้อนกลับ" : "Back"}
            </button>
          </div>
          <div className="flex justify-end items-center">
            {/* <div className="">ถัดไป</div> */}
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-8 rounded-[20px]"
              onClick={() => navigate("/safety-examination")}
            >
              {language === Language.TH ? "ทําแบบทดสอบ" : "Start"}
            </button>
            <FontAwesomeIcon
              className=""
              icon="angles-right"
              size="2x"
              color="#333"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestProcess;
