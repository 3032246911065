import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import tw from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "../../../api";
import { useLanguage } from "../../../providers/language";
import { Language } from "../../../schemas/language";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

interface Store {
  id: number;
  tenant_id: string;
  name_th: string;
  name_en: string;
}
const customStyles: (error: any) => StylesConfig<any> = (error: any) => ({
  input: (provided) => ({
    ...provided,
    ...tw`m-0 sm:text-sm`,
  }),
  control: (provided) => ({
    ...provided,
    ...(error
      ? tw`w-full py-0 border border-red-500 rounded-md shadow-sm hover:outline-none hover:border-sky-500 hover:ring-sky-500 hover:ring-1`
      : tw`w-full py-0 border border-slate-300 rounded-md shadow-sm hover:outline-none hover:border-sky-500 hover:ring-sky-500 hover:ring-1`),
  }),
});
enum Title {
  MR = "Mr.",
  MS = "Ms.",
  MRS = "Mrs.",
}

const TitleMap = {
  [Title.MR]: {
    [Language.TH]: "นาย",
    [Language.EN]: "Mr.",
  },
  [Title.MS]: {
    [Language.TH]: "นางสาว",
    [Language.EN]: "Ms.",
  },
  [Title.MRS]: {
    [Language.TH]: "นาง",
    [Language.EN]: "Mrs.",
  },
};

interface CreateCustomerResponse {
  code: number;
  data: { id: number; exam_result_id: number };
  message_en: string;
  message_th: string;
}

interface StartFormProps {
  gotoExam: (staffId: number, examId: number) => void;
}
function StartForm({ gotoExam }: StartFormProps) {
  const navigate = useNavigate();
  const { language } = useLanguage();

  const [prefix, setPrefix] = useState<string>("");
  const [name, setName] = useState<string>("");

  const [idCard, setIdCard] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [store, setStore] = useState<Store | null>(null);

  const [prefixErr, setPrefixErr] = useState<string>("");
  const [nameErr, setNameErr] = useState<string>("");
  const [idCardErr, setIdCardErr] = useState<string>("");
  const [emailErr, setEmailErr] = useState<string>("");
  const [phoneErr, setPhoneErr] = useState<string>("");
  const [storeErr, setStoreErr] = useState<string>("");

  const [limitErr, setLimitErr] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);
  const [inputStoreValue, setInputStoreValue] = useState<string>("");
  const [stores, setStores] = useState<Store[]>([]);

  const handleStart = (e: React.FormEvent) => {
    e.preventDefault();
    setPrefixErr("");
    setNameErr("");
    setIdCardErr("");
    setEmailErr("");
    setPhoneErr("");
    setStoreErr("");
    let err = false;
    if (!prefix) {
      setPrefixErr("Required");
      err = true;
    }
    if (!name) {
      setNameErr("Required");
      err = true;
    }
    if (!idCard) {
      setIdCardErr("Required");
      err = true;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailErr("Invalid email address");
      err = true;
    }
    if (!phone) {
      setPhoneErr("Required");
      err = true;
    }
    if (!store) {
      setStoreErr("Required");
      err = true;
    }

    if (err) {
      return;
    }

    api
      .post<CreateCustomerResponse>(`staff/create/`, {
        staff_id: idCard,
        prefix: TitleMap[Title.MRS][language],
        name: name,
        email: email,
        tenant_id: store?.id,
        phone_number: phone,
      })
      .then((res) => {
        const { data } = res;
        if (data.data) {
          gotoExam(data.data.id, data.data.exam_result_id);
        }
      })
      .catch((e: AxiosError<any>) => {
        if (e.response?.data) {
          if (e.response.data.code === 900) {
            console.log(e.response.data);
            const { message_en, message_th } = e.response.data;
            setLimitErr(language === Language.TH ? message_th : message_en);
            console.log(language === Language.TH ? message_th : message_en);
          } else {
            const { staff_id, name, phone_number, prefix, tenant_id } =
              e.response.data;
            setPrefixErr(prefix);
            setIdCardErr(staff_id);
            setNameErr(name);
            setPhoneErr(phone_number);
            setStoreErr(tenant_id);
          }
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    api
      .get<Store[]>("tenant/list", {
        params: {
          limit: 10,
        },
      })
      .then((res) => {
        const { data } = res;
        setStores(data);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(true);
      api
        .get<Store[]>("tenant/list", {
          params: {
            name: inputStoreValue,
            limit: 10,
          },
        })
        .then((res) => {
          const { data } = res;
          setStores(data);
          setIsLoading(false);
        });
    }, 500);
    return () => clearTimeout(timeout);
  }, [language, inputStoreValue]);

  return (
    <div className="p-4">
      <h1 className="text-xl font-medium">
        {language === Language.TH ? "ข้อมูลพนักงาน" : "Tenant Staff"}
      </h1>
      <form onSubmit={handleStart}>
        <div className="grid grid-cols-4 gap-4 mt-8">
          <div className="col-span-1">
            <label className="block">
              <span className="block text-md font-medium text-slate-700">
                {language === Language.TH ? "คำนำหน้า" : "Prefix"}
              </span>

              <select
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
                className={`appearance-none w-full border ${
                  prefixErr ? "border-red-500" : "border-slate-300"
                } rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm`}
                required
              >
                <option value="" defaultChecked disabled></option>
                <option value={Title.MR}>{TitleMap[Title.MR][language]}</option>
                <option value={Title.MS}>{TitleMap[Title.MS][language]}</option>
                <option value={Title.MRS}>
                  {TitleMap[Title.MRS][language]}
                </option>
              </select>
              <p className="px-1 text-red-500 text-xs italic">{prefixErr}</p>
            </label>
          </div>
          <div className="col-span-3">
            <label className="block">
              <span className="block text-md font-medium text-slate-700">
                {language === Language.TH ? "ชื่อ - นามสกุล" : "Name - Surname"}
              </span>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`w-full border ${
                  nameErr ? "border-red-500" : "border-slate-300"
                } rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm`}
                required
              />
              <p className="px-1 text-red-500 text-xs italic">{nameErr}</p>
            </label>
          </div>
          <div className="col-span-4">
            <label className="block">
              <span className="block text-md font-medium text-slate-700">
                {language === Language.TH ? "เลขบัตรประชาชน" : "ID Card Number"}
              </span>
              <input
                type="text"
                value={idCard}
                onChange={(e) => setIdCard(e.target.value)}
                className={`w-full border ${
                  idCardErr ? "border-red-500" : "border-slate-300"
                } rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm`}
                required
              />
              <p className="px-1 text-red-500 text-xs italic">{idCardErr}</p>
            </label>
          </div>
          <div className="col-span-2">
            <label className="block">
              <span className="block text-md font-medium text-slate-700">
                {language === Language.TH ? "อีเมล" : "Email Address"}
              </span>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`w-full border ${
                  emailErr ? "border-red-500" : "border-slate-300"
                } rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm`}
                required
              />
              <p className="px-1 text-red-500 text-xs italic">{emailErr}</p>
            </label>
          </div>
          <div className="col-span-2">
            <label className="block">
              <span className="block text-md font-medium text-slate-700">
                {language === Language.TH ? "เบอร์โทรศัพท์" : "Phone Number"}
              </span>
              <input
                type="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className={`appearance-none w-full border ${
                  phoneErr ? "border-red-500" : "border-slate-300"
                } rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm`}
                required
              />
              <p className="px-1 text-red-500 text-xs italic">{phoneErr}</p>
            </label>
          </div>

          <div className="col-span-4">
            <label className="block">
              <span className="block text-md font-medium text-slate-700">
                {language === Language.TH ? "ชื่อร้านค้า" : "Tenant Name"}
                <span className="ml-2 text-sm font-medium text-slate-700">
                  (
                  {language === Language.TH
                    ? `หากไม่พบ กรุณาพิมพ์ “อื่นๆ”`
                    : `If not found, please type "Other"`}
                  )
                </span>
              </span>
              <Select
                classNamePrefix="select"
                isLoading={isLoading}
                isClearable={true}
                isSearchable={true}
                name="color"
                options={stores}
                getOptionLabel={(option: Store) =>
                  language === Language.TH
                    ? `${option.name_th} (${option.name_en})`
                    : `${option.name_en} (${option.name_th})`
                }
                isOptionSelected={(option, selected) =>
                  selected.find((select) => select.id === option.id) !==
                  undefined
                }
                styles={customStyles(storeErr)}
                onChange={(value) => setStore(value as Store)}
                onInputChange={(value) => setInputStoreValue(value)}
                inputValue={inputStoreValue}
                value={store}
                placeholder={""}
              />
              <p className="px-1 text-red-500 text-xs italic">{storeErr}</p>
            </label>
          </div>
        </div>

        <div className="mt-4">
          {limitErr ? (
            // <div className="text-red-600 font-bold">{limitErr}</div>
            <p className="text-right px-1 text-red-500 italic">{limitErr}</p>
          ) : null}
          <div className="flex justify-between py-2">
            <div className="flex justify-end items-center">
              {/* <div className="">ถัดไป</div> */}
              <FontAwesomeIcon
                className=""
                icon="angles-left"
                size="2x"
                color="#333"
              />
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-8 rounded-[20px]"
                type="button"
                onClick={() => {
                  navigate("/request-process");
                }}
              >
                {language === Language.TH ? "ย้อนกลับ" : "Back"}
              </button>
            </div>
            <div className="flex justify-end items-center">
              {/* <div className="">ถัดไป</div> */}
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-8 rounded-[20px]"
                type="submit"
              >
                {language === Language.TH ? "ทำแบบทดสอบ" : "Start"}
              </button>
              <FontAwesomeIcon
                className=""
                icon="angles-right"
                size="2x"
                color="#333"
              />
            </div>
          </div>

          {/* <div className="float-right mt-2">
            <button
              onClick={handleStart}
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-8 rounded"
            >
              {language === Language.TH ? "เริ่ม" : "Start"}
            </button>
          </div> */}
        </div>
      </form>
    </div>
  );
}

export default StartForm;
