import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../providers/language";
import { Language } from "../../schemas/language";
import userSvg from "../../assets/icons/1-white.svg";
import formSvg from "../../assets/icons/2-white.svg";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { api } from "../../api";

interface NewsImageResponse {
  code: number;
  data: NewsImageResponseData[];
}
interface NewsImageResponseData {
  id: number;
  image_url: string;
}

function Home() {
  const navigate = useNavigate();
  // const { language, changeLanguage } = useLanguage();
  const [newsImage, setNewsImage] = useState<ReactImageGalleryItem[]>([]);

  useEffect(() => {
    api.get<NewsImageResponse>(`/publicrelations/list/`).then((res) => {
      const { data } = res;
      if (data.code === 200) {
        setNewsImage(
          data.data.map((value) => ({
            original: value.image_url,
            // thumbnail: value.image_url,
          }))
        );
        return;
      }
      setNewsImage([]);
    });
  }, []);
  return (
    <>
      <div className="relative w-full h-full flex justify-center items-center bg-home-bg bg-cover">
        <div className="p-6 w-1/2 bg-white/70 h-full grid content-around">
          <div
            className="flex items-center justify-center w-full h-[200px] bg-red-600 hover:bg-red-500 active:bg-red-600 rounded-[20px] shadow-[10px_10px] cursor-pointer"
            onClick={() => navigate("/request-process")}
          >
            {/* <FontAwesomeIcon
              className="px-4"
              icon="address-card"
              size="6x"
              color="white"
            /> */}
            <img className="mx-4 h-[170px]" src={userSvg} alt="forms" />
            <div className="text-white font-medium">
              <div className="text-left text-lg">
                ขั้นตอนบัตรประจําตัวพนักงานร้านค้า
              </div>
              <div className="text-left">TENANT CARD REQUEST PROCESS</div>
            </div>
          </div>
          <div
            className="flex items-center justify-center w-full h-[200px] bg-red-600 hover:bg-red-500 active:bg-red-600 rounded-[20px] shadow-[10px_10px] cursor-pointer"
            onClick={() => navigate("/forms")}
          >
            {/* <FontAwesomeIcon
              className="px-4"
              icon="file-pen"
              size="6x"
              color="white"
            /> */}

            <img className="mx-4 h-[150px]" src={formSvg} alt="forms" />
            <div className="text-white font-medium">
              <div className="text-left text-lg">แบบฟอร์ม</div>
              <div className="text-left">REQUEST FORMS</div>
            </div>
          </div>
        </div>
        <div className="w-1/2 h-full">
          {/* <div className="p-6 bg-white/70 rounded-[16px] text-lg font-medium">
            หน้าแรกประชาสัมพันธ์ กิจกรรมที่จะเกิดขึ้น
          </div> */}
          <ImageGallery
            items={newsImage}
            showBullets={true}
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            autoPlay={true}
            slideInterval={3000}
            showThumbnails={false}
            infinite={true}
          />
          ;
        </div>


        {/* <div className="absolute px-3 top-0 left-[100%] w-[300px]">
          <Link to="/safety-examination">Go to Safety Examination</Link>
        </div> */}

      </div>
      <div className="fixed  bottom-0 left-[50%] h-[50px] w-[50vw] max-w-[910px]">
        <div className="absolute m-2 p-3 w-[300px] right-0 bottom-0 bg-[#f5f5f59a] rounded-lg">
          <h1 className="text-lg font-bold mb-2">ติดต่อสำนักงาน</h1>
          <p>บริษัท เอสเอฟ ดีเวลอปเมนท์ จำกัด</p>
          <p>38, 38/1-3, 39 หมู่ที่ 6</p>
          <p>ถนนบางนา-ตราด</p>
          <p>ตำบลบางแก้ว อำเภอบางพลี</p>
          <p>จังหวัดสมุทรปราการ 10540</p>
          <p className="mt-6"></p>
          <p>เปิดทำการทุกวัน</p>
          <p>เวลา 09.00 - 18.00 น.</p>
          <p className="mt-6"></p>
          <p>โทรศัพท์ : +66 2-105-1000</p>
          <p className="mt-4"></p>
          <p>อีเมล : reception@mega-bangna.com</p>
        </div>
      </div>
    </>
  );
}

export default Home;
