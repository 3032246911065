import { useLanguage } from "../../providers/language";
import { Language } from "../../schemas/language";
import userSvg from "../../assets/icons/1-black.svg";
import menuCoinList from "../../assets/icons/7-black.svg";
import userSigning from "../../assets/icons/8-black.svg";
import camera from "../../assets/icons/9-black.svg";

function RequestProcessDetail() {
  const { language, changeLanguage } = useLanguage();

  return (
    <>
      <div className="w-full h-full bg-white">
        <div className="flex pt-4 justify-center items-center">
          <div className="mr-2">
            <img
              className="relative top-[4px] w-[100px] inline"
              src={userSvg}
              alt="userSvg"
            />
          </div>
          <div className="text-2xl font-bold">
            <div>ขั้นตอนการทำบัตรประจำตัวพนักงานร้านค้า</div>
            <div className="text-xl font-bold">TENANT CARD REQUEST PROCESS</div>
          </div>
        </div>
        <div className="p-10">
          <div className="px-4 relative grid grid-cols-3">
            <div className="absolute top w-full top-[130px] h-[60px] bg-red-600"></div>
            {/*  */}
            <div className="relative">
              <div className="flex justify-center items-end h-[120px]">
                <img
                  className="h-[120px] inline"
                  src={menuCoinList}
                  alt="menuCoinList"
                />
              </div>
              <div className="mt-[10px] flex justify-center items-center h-[60px]">
                <div className="text-white text-lg text-center font-bold px-[12px]">
                  {language === Language.TH
                    ? "เตรียมเอกสาร เพื่อยื่นขอทำบัตร"
                    : "Submit the Documents"}
                </div>
              </div>
              <div className="p-4 px-10">
                <ul className="list-outside list-['-_'] mt-2">
                  {language === Language.TH ? (
                    <>
                      <li>สำเนาบัตรประชาชน / สำเนาพาสปอร์ต</li>
                      <li>ผลข้อสอบ</li>
                      <li>
                        กรณีต้องการสิทธิจอดรถ ขอสำเนาทะเบียน รถ
                        ที่มีชื่อผู้ครอบครองตรงกับชื่อพนักงาน
                        หรือบุคคลในครอบครัว
                      </li>
                      <li>ค่าทำบัตรใหม่ 50 บาท</li>
                    </>
                  ) : (
                    <>
                      <li>ID Card Request Form / Copy ID Card or Passport</li>
                      <li>Safety Examination Result</li>
                      <li>
                        For parking card: a copy of car register indicated staff
                        tenant name or family name as the owner
                      </li>
                      <li>New card fee THB 50.00</li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            {/*  */}
            <div className="relative">
              <div className="flex justify-center items-end h-[120px] mb-[14px]">
                <img
                  className="h-[120px] inline"
                  src={userSigning}
                  alt="userSigning"
                />
              </div>
              <div className="flex justify-center items-center my-1 h-[52px] bg-white">
                <div className="text-[#333] text-md text-center font-bold px-[13px]">
                  {language === Language.TH
                    ? "ติดต่อ RECEPTION ที่สำนักงานเมกาบางนา"
                    : "Contact Reception Counter"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: -13,
                    width: 0,
                    height: 0,
                    borderTop: "26px solid transparent",
                    borderBottom: "26px solid transparent",
                    backgroundColor: "white",

                    borderLeft:
                      "26px solid rgb(220 38 38 / var(--tw-bg-opacity))",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: -13,
                    width: 0,
                    height: 0,
                    borderTop: "26px solid transparent",
                    borderBottom: "26px solid transparent",
                    backgroundColor: "rgb(220 38 38 / var(--tw-bg-opacity))",
                    borderLeft: "26px solid white",
                  }}
                />
              </div>
              <div className="p-4 px-10">
                <ul className="list-outside list-['-_'] mt-2">
                  {language === Language.TH ? (
                    <>
                      <li>ยื่นเอกสาร</li>
                      <li>ชำระเงิน</li>
                      <li>นัดหมายวันถ่ายรูป</li>
                    </>
                  ) : (
                    <>
                      <li>Submit documents</li>
                      <li>Pay a fee</li>
                      <li>Make an appointment</li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            {/*  */}
            <div className="relative">
              <div className="flex justify-center items-end h-[120px]">
                <img className="h-[100px] inline" src={camera} alt="camera" />
                <img className="h-[120px] inline" src={userSvg} alt="userSvg" />
              </div>
              <div className="mt-[10px] flex justify-center items-center h-[60px]">
                <div className="text-white text-lg text-center font-bold px-[12px]">
                  {language === Language.TH ? "วันนัดหมาย" : "Appointment Date"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: -16,
                    width: 0,
                    height: 0,
                    borderTop: "30px solid transparent",
                    borderBottom: "30px solid transparent",
                    backgroundColor: "white",

                    borderLeft:
                      "30px solid rgb(220 38 38 / var(--tw-bg-opacity))",
                  }}
                />
              </div>
              <div className="p-4 px-10">
                <ul className="list-outside list-['-_'] mt-2">
                  {language === Language.TH ? (
                    <>
                      <li>ถ่ายรูปทำบัตร</li>
                      <li>พร้อมรับบัตรทันที</li>
                    </>
                  ) : (
                    <>
                      <li>Take a photo</li>
                      <li>Receive Tenant Card</li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestProcessDetail;
