import { Outlet, useNavigate } from "react-router-dom";
import { useLanguage } from "../../providers/language";
import { Language } from "../../schemas/language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import undraw from "../../svgs/undraw_shopping_app_flsj.svg";
const SafetyExaminationLayout = () => {
  const navigate = useNavigate();
  const { language, changeLanguage } = useLanguage();

  return (
    <div className="w-full h-screen overflow-hidden relative">
      <div
        style={{
          position: "absolute",
          width: "90%",
          height: "200%",
          left: "0",
          top: "50%",
          transform: "translate(-50%, -50%)",
          background: "#FF0000",
          margin: 0,
          borderRadius: "50%",
        }}
      ></div>
      <div className="py-[2vh] px-[4vh] fixed top-0 right-0">
        <div className="w-full px-2 py-4 flex justify-end">
          <div className="flex justify-center items-center">
            <div className="cursor-pointer" onClick={() => navigate("/")}>
              <FontAwesomeIcon
                className="px-2"
                icon="house-chimney"
                size="xl"
                color="rgb(82 82 82)"
              />
            </div>

            <span className="text-lg">Language</span>
          </div>
          <div className="mx-2 bg-neutral-600 border-neutral-600 border-[1px] rounded-full" />
          <div className="flex flex-row">
            <div
              className={`w-10 h-10 flex justify-center items-center text-xl cursor-pointer bg-blue-700 rounded-full text-white ${
                language === Language.TH
                  ? "underline"
                  : "hover:text-blue-200 hover:underline"
              }`}
              onClick={() => changeLanguage(Language.TH)}
            >
              TH
            </div>
            <div className="px-1"></div>
            <div
              className={`w-10 h-10 flex justify-center items-center  text-xl cursor-pointer bg-blue-700 rounded-full text-white ${
                language === Language.EN
                  ? "underline"
                  : "hover:text-blue-200 hover:underline"
              }`}
              onClick={() => changeLanguage(Language.EN)}
            >
              EN
            </div>
          </div>
        </div>
      </div>
      <div className="h-full flex flex-row p-[8vh]">
        <div className="relative basis-1/2 content-center">
          <div className="h-full grid absolute content-center">
            <div className="w-[70%]">
              <h1 className="text-[3.25rem] font-medium text-white">
                Megabangna{" "}
                <span className="inline-block">Safety Examination</span>
              </h1>
              <img src={undraw} className="undraw mt-8" alt="undraw" />
            </div>
          </div>
        </div>
        <div className="relative grid basis-1/2 content-center">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SafetyExaminationLayout;
