import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import Home from "../pages/Home";

import SafetyExaminationLayout from "../layouts/SafetyExaminationLayout";
import StartForm from "../pages/SafetyExamination/StartForm";
import Question from "../pages/SafetyExamination/Question";
import SummaryScore from "../pages/SafetyExamination/SummaryScore";
import RequestProcess from "../pages/RequestProcess";

import RequestProcessDetail from "../pages/RequestProcessDetail";
import SafetyExamination from "../pages/SafetyExamination";

import Forms from "../pages/Forms";
import WorkPermit from "../pages/Forms/WorkPermit";
import Marketing from "../pages/Forms/Marketing";
import StaffAndParking from "../pages/Forms/StaffAndParking";
import ClaimApplication from "../pages/Forms/ClaimApplication";
import OtherForms from "../pages/Forms/OtherForms";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/request-process" element={<RequestProcess />} />
          <Route path="/request-process-2" element={<RequestProcessDetail />} />
          <Route path="/forms">
            <Route index element={<Forms />} />
            <Route path="/forms/work_permit" element={<WorkPermit />} />
            <Route path="/forms/marketing" element={<Marketing />} />
            <Route path="/forms/staff_and_parking" element={<StaffAndParking />} />
            <Route path="/forms/claim_application" element={<ClaimApplication />} />
            <Route path="/forms/other_forms" element={<OtherForms />} />

            
          </Route>
        </Route>
        <Route path="safety-examination" element={<SafetyExaminationLayout />}>
          <Route index element={<SafetyExamination />} />
        </Route>
        <Route
          path="*"
          element={
            <div className="h-screen flex items-center">
              <div className="text-center w-full">
                <h1 className="text-2xl">404 - Not Found!</h1>
                <Link to="/">Go Home</Link>
              </div>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
