import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguage } from "../../../providers/language";
import { Language } from "../../../schemas/language";
import marketingSvg from "../../../assets/icons/icon-21.svg";

const DOWNLOAD_URL = process.env.REACT_APP_DOWNLOAD_API_URL || "";

const formsFiles = [
  {
    form_id: "10",
    form_name: "แบบฟอร์มขอข้อมูลสถิติจำนวนลูกค้า",
    form_name_en: "Application form for visitor statistics",
    url: DOWNLOAD_URL + "/?filename=Form10 Application form for visitor statistics.pdf",
  },
  {
    form_id: "15",
    form_name: "แบบคำร้องสำหรับกิจกรรมส่งเสริมการขาย/ตลาด",
    form_name_en: "Promo-Action Marketing Event Request",
    url: DOWNLOAD_URL + "/?filename=Form15 Promo-Action Marketing Event Request.pdf",
  },
  {
    form_id: "18",
    form_name: "แบบแจ้งประกาศเสียงตามสายภายในเมกาบางนา",
    form_name_en: "Voice Over Request",
    url: DOWNLOAD_URL + "/?filename=Form18 Voice Over Request.pdf",
  },
  {
    form_id: "19",
    form_name: "แบบจองสื่อ J Flag และ Poster Stand",
    form_name_en: "J Flag and Poster Stand Request",
    url: DOWNLOAD_URL + "/?filename=Form19 J Flag and Poster Stand Request.pdf",
  },
];

function Marketing() {
  const { language } = useLanguage();

  return (
    <>
      <div className="w-full bg-white">
        <div className="flex flex-wrap pt-4 justify-center">
          <div className="mx-6 p-3 mb-6">
            <div className="flex justify-center">
              <img className="h-[12rem]" src={marketingSvg} alt="forms" />
            </div>
            <div className="font-medium">
              <div className="text-center text-xl">
                {language === Language.TH
                  ? "แบบฟอร์มการตลาด"
                  : "Marketing Form"}
              </div>
            </div>
          </div>
        </div>

        <div className="px-20 py-4">
          {formsFiles.map((data, key) => (
            <div className="mt-1">
              <a
                key={key}
                className=""
                href={data.url}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="pr-2"
                  icon="file-lines"
                  // size="2"
                  color="#333"
                />
                {`${language === Language.TH ? "แบบฟอร์ม" : "Form"} ${
                  data.form_id ? data.form_id : "-"
                }`}
                &nbsp;&nbsp;
                {language === Language.TH ? data.form_name : data.form_name_en}
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Marketing;
