import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "../../../api";
import { useLanguage } from "../../../providers/language";
import { Language } from "../../../schemas/language";

interface Question {
  id: number;
  choices: Choice[];
  image_url?: string | null;
  description_th: string;
  description_en: string;
  is_active: boolean;
  created_at: string;
}
interface Choice {
  id: number;
  description_th: string;
  description_en: string;
  created_at: string;
}
interface Answer {
  question: number;
  choice: number;
}
interface QuestionPageProps {
  staffId: number | null;
  examId: number | null;

  gotoResult: () => void;
}

const examTimeout = 30 * 60 * 1000; // 30 minute
function QuestionPage({ staffId, examId, gotoResult }: QuestionPageProps) {
  const { language } = useLanguage();
  const [questionList, setQuestionList] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [selectedChoice, setSelectedChoice] = useState<number | null>(null);
  const [answerList, setAnswerList] = useState<Answer[]>([]);
  const [correctAnswer, setCorrectAnswer] = useState<Answer | null>(null);

  const [endTime, setEndTime] = useState<Date | null>(null);
  const [isTimeout, setIsTimeout] = useState<boolean>(false);

  const [score, setScore] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    if (staffId && examId) {
      setLoading(true);
      api
        .post(`staff/examresult/create/`, {
          id: staffId,
          exam_result_id: examId,
          data: answerList,
          score: score,
        })
        .then((res) => {
          const { data } = res;
          gotoResult();
        })
        .finally(() => {
          setLoading(false);
        });
      setSelectedChoice(null);
      setCorrectAnswer(null);
      // setCurrentQuestionIndex(0);
    }
  }, [answerList, staffId, examId, score, gotoResult]);

  const handleCheckAnswer = () => {
    if (loading) {
      return;
    }
    if (selectedChoice != null && currentQuestionIndex in questionList) {
      setLoading(true);
      setAnswerList([
        ...answerList,
        {
          question: questionList[currentQuestionIndex].id,
          choice: selectedChoice,
        },
      ]);
      api
        .get(
          `question/${questionList[currentQuestionIndex].id}/choice/${selectedChoice}/check/`
        )
        .then((res) => {
          const { data } = res;
          if (data.data == null) {
            setCorrectAnswer({
              question: questionList[currentQuestionIndex].id,
              choice: selectedChoice,
            });
            setScore(score + 1);
          } else {
            if (data.data.length > 0)
              setCorrectAnswer({
                question: questionList[currentQuestionIndex].id,
                choice: data.data[0].id,
              });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex === questionList.length - 1) {
      handleSubmit();
      return;
    }
    if (selectedChoice != null && currentQuestionIndex in questionList) {
      setSelectedChoice(null);
      setCorrectAnswer(null);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  useEffect(() => {
    setLoading(true);
    api
      .get<Question[]>(`question/list/`)
      .then((res) => {
        const { data } = res;
        setQuestionList(data);
        setCurrentQuestionIndex(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const now = new Date();
    const endTime = new Date(now);
    endTime.setTime(now.getTime() + examTimeout);
    setEndTime(endTime);
    const timeout = setTimeout(() => {
      setIsTimeout(true);
    }, examTimeout);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (isTimeout) {
      handleSubmit();
    }
  }, [isTimeout, handleSubmit]);

  if (!(currentQuestionIndex in questionList)) {
    return null;
  }
  const image_url = questionList[currentQuestionIndex].image_url;

  return (
    <div className="p-4">
      <h1 className="text-xl font-medium">
        {`${currentQuestionIndex + 1}. `}
        {language === Language.TH
          ? questionList[currentQuestionIndex].description_th
          : questionList[currentQuestionIndex].description_en}
      </h1>
      {image_url ? (
        <div className="flex justify-center mt-4">
          <img
            src={image_url ? image_url : ""}
            className="h-[35vh]"
            alt={
              language === Language.TH
                ? questionList[currentQuestionIndex].description_th
                : questionList[currentQuestionIndex].description_en
            }
          />
        </div>
      ) : null}

      <div className="grid gap-2 mt-6">
        {questionList[currentQuestionIndex].choices.map((choice, index) => {
          return (
            <div
              key={index}
              className={
                "flex items-center pl-4 rounded " +
                (correctAnswer?.choice === choice.id
                  ? "bg-emerald-200 border border-emerald-500"
                  : correctAnswer != null &&
                    selectedChoice === choice.id &&
                    selectedChoice !== correctAnswer?.choice
                  ? "bg-red-200 border border-emerald-500"
                  : "border border-gray-200 dark:border-gray-700")
              }
            >
              <input
                id={`${choice.id}`}
                type="radio"
                value={`${choice.id}`}
                checked={selectedChoice === choice.id}
                name="bordered-radio"
                onChange={(e) =>
                  correctAnswer == null
                    ? setSelectedChoice(parseInt(e.target.value))
                    : undefined
                }
                className="w-5 h-5 shrink-0 rounded-full focus:rounded-full text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
              />
              <label
                htmlFor={`${choice.id}`}
                className="pl-1 py-3 ml-2 w-full text-sm font-medium text-neutral-900 cursor-pointer"
              >
                {language === Language.TH
                  ? choice.description_th
                  : choice.description_en}
              </label>
              {correctAnswer ? (
                <div className="px-4 w-16 text-center">
                  {correctAnswer?.choice === choice.id ? (
                    <FontAwesomeIcon icon="check" size="xl" color="green" />
                  ) : correctAnswer != null &&
                    selectedChoice === choice.id &&
                    selectedChoice !== correctAnswer?.choice ? (
                    <FontAwesomeIcon icon="xmark" size="xl" color="red" />
                  ) : null}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="px-4 text-lg font-medium">
          <span>{language === Language.TH ? "เหลือเวลา" : "Time left"}: </span>
          {endTime ? <CountDown target={endTime} /> : null}
        </div>

        {correctAnswer == null ? (
          <div className="float-right flex justify-end items-center">
            <button
              onClick={
                correctAnswer == null ? handleCheckAnswer : handleNextQuestion
              }
              disabled={correctAnswer == null && selectedChoice == null}
              className={
                (correctAnswer == null && selectedChoice == null
                  ? "bg-red-200"
                  : "bg-red-500 hover:bg-red-700") +
                " text-white font-bold py-2 px-8 rounded-[20px]"
              }
            >
              {language === Language.TH ? "ส่ง" : "Send"}
            </button>
          </div>
        ) : (
          <div className="float-right flex justify-end items-center">
            <button
              onClick={
                correctAnswer == null ? handleCheckAnswer : handleNextQuestion
              }
              disabled={correctAnswer == null && selectedChoice == null}
              className={
                (correctAnswer == null && selectedChoice == null
                  ? "bg-red-200"
                  : "bg-red-500 hover:bg-red-700") +
                " text-white font-bold py-2 px-8 rounded-[20px]"
              }
            >
              {language === Language.TH ? "ต่อไป" : "Next"}
            </button>
            <FontAwesomeIcon
              className=""
              icon="angles-right"
              size="2x"
              color="#333"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default QuestionPage;

function CountDown({ target }: { target: Date }) {
  const [timeleft, setTimeleft] = useState<number>(0);

  useEffect(() => {
    const countDownDate = new Date(target).getTime();
    setTimeleft(countDownDate - new Date().getTime());
    const interval = setInterval(() => {
      const diff = countDownDate - new Date().getTime();
      setTimeleft(diff > 0 ? diff : 0);
      console.log(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, [target]);

  return <span>{getReturnValues(timeleft)}</span>;
}

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return `${minutes}:${`${seconds}`.padStart(2, "0")}`;
};
