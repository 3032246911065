import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useLanguage } from "../../providers/language";
import { Language } from "../../schemas/language";

enum Tab {
  Card = "Card",
  Form = "Form",
}

function MainLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { language, changeLanguage } = useLanguage();

  const [tab, setTab] = useState<Tab>(Tab.Card);

  useEffect(() => {
    if (location.pathname.startsWith("/forms")) {
      setTab(Tab.Form);
    } else {
      setTab(Tab.Card);
    }
  }, [location.pathname]);

  return (
    <div className="w-full bg-neutral-100">
      <header className="h-[152px] bg-white">
        <div className="flex flex-row">
          <div className="flex-none p-8 pb-4 w-[300px] h-[120px]">
            <Link to="/">
              <img src={require("../../assets/logo_mega.png")} alt={"logo"} />
            </Link>
          </div>
          <div className="flex grow justify-center items-end">
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="py-2 px-4 flex justify-center">
                <div
                  className={`flex relative items-end cursor-pointer
                  after:left-0
                  after:bottom-0
                  after:absolute 
                  after:w-full 
                  after:border-[3px] 
                  after:rounded-full
                  ${
                    tab === Tab.Card
                      ? "after:bg-red-600 after:border-red-600"
                      : `after:border-transparent hover:text-neutral-500 hover:after:bg-red-400 hover:after:border-red-400`
                  }
                  active:text-neutral-700
                  active:after:bg-red-700
                  active:after:border-red-700
                  `}
                  onClick={() => {
                    setTab(Tab.Card);
                    navigate("/");
                  }}
                >
                  <div className="p-1 text-lg">
                    {language === Language.TH
                      ? "บัตรประจำตัวพนักงานร้านค้า"
                      : "Tenant Card Request"}
                  </div>
                </div>
              </div>
              <div className="py-2 px-4 flex justify-center">
                <div
                  className={`flex relative items-end cursor-pointer
                  after:left-0
                  after:bottom-0
                  after:absolute 
                  after:w-full 
                  after:border-[3px] 
                  after:rounded-full
                  ${
                    tab === Tab.Form
                      ? "after:bg-red-600 after:border-red-600"
                      : `after:border-transparent hover:text-neutral-500 hover:after:bg-red-400 hover:after:border-red-400`
                  }
                  active:text-neutral-700
                  active:after:bg-red-700
                  active:after:border-red-700
                  `}
                  onClick={() => {
                    setTab(Tab.Form);
                    navigate("/forms");
                  }}
                >
                  <div className="p-1 text-lg">
                    {language === Language.TH ? "แบบฟอร์ม" : "Request Form"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-[300px] items-end">
            <div className="w-full px-2 py-4 flex justify-end">
              <div className="flex justify-center items-center">
                <div className="cursor-pointer" onClick={() => navigate("/")}>
                  <FontAwesomeIcon
                    className="px-2"
                    icon="house-chimney"
                    size="xl"
                    color="rgb(82 82 82)"
                  />
                </div>

                <span className="text-lg">Language</span>
              </div>
              <div className="mx-2 bg-neutral-600 border-neutral-600 border-[1px] rounded-full" />
              <div className="flex flex-row">
                <div
                  className={`w-10 h-10 flex justify-center items-center text-xl cursor-pointer bg-blue-700 rounded-full text-white ${
                    language === Language.TH
                      ? "underline"
                      : "hover:text-blue-200 hover:underline"
                  }`}
                  onClick={() => changeLanguage(Language.TH)}
                >
                  TH
                </div>
                <div className="px-1"></div>
                <div
                  className={`w-10 h-10 flex justify-center items-center  text-xl cursor-pointer bg-blue-700 rounded-full text-white ${
                    language === Language.EN
                      ? "underline"
                      : "hover:text-blue-200 hover:underline"
                  }`}
                  onClick={() => changeLanguage(Language.EN)}
                >
                  EN
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-6 bg-red-600"></div>
        <div className="h-2 bg-blue-700"></div>
      </header>
      <div className="flex w-full overflow-x-auto overflow-x-hidden">
        <div className="m-auto bg-white w-full max-w-[1200px] h-[calc(100vh-152px)]">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
