import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguage } from "../../../providers/language";
import { Language } from "../../../schemas/language";
import svg from "../../../assets/icons/icon-20.svg";

const DOWNLOAD_URL = process.env.REACT_APP_DOWNLOAD_API_URL || "";

const formsFiles = [
  {
    form_id: "01",
    form_name: "แบบฟอร์มขออนุญาตเข้าทำงาน",
    form_name_en: "Enquiry Work",
    url: DOWNLOAD_URL + "/?filename=Form01 Enquiry Work.pdf",
  },
  {
    form_id: "05",
    form_name: "แบบฟอร์มขอเปิด-ปิดระบบ",
    form_name_en: "Services Turn On Request Form",
    url: DOWNLOAD_URL + "/?filename=Form05 Services Turn On Request Form.pdf",
  },
  {
    form_id: "08",
    form_name: "ใบอนุญาตการทำงานที่มีประกายไฟ หรือสารไวไฟ",
    form_name_en: "Hotwork Permit",
    url: DOWNLOAD_URL + "/?filename=Form08 Hotwork Permit.pdf",
  },
  {
    form_id: "11",
    form_name: "แบบฟอร์มนำของเข้า-ออก",
    form_name_en: "Loading-Unloading Request Form",
    url: DOWNLOAD_URL + "/?filename=Form11 Loading-Unloading Request Form.pdf",
  },
  {
    form_id: "12",
    form_name: "แบบฟอร์มคืนกุญแจตู้จดหมาย",
    form_name_en: "Information of mail box key handover back",
    url:
      DOWNLOAD_URL +
      "/?filename=Form12 Information of mail box key handover back.pdf",
  },
  {
    form_id: "13",
    form_name: "แบบฟอร์มใบรับกุญแจตู้จดหมาย",
    form_name_en: "Mailbox key receiving form",
    url: DOWNLOAD_URL + "/?filename=Form13 Mailbox key receiving form.pdf",
  },
  {
    form_id: "14",
    form_name: "แบบคำขอเปิดปิดระบบป้องกันอัคคีภัยภายในพื้นที่ตกแต่ง",
    form_name_en: "Request for Disabling fire protection",
    url:
      DOWNLOAD_URL +
      "/?filename=Form14 Request for Disabling fire protection.PDF",
  },
  {
    form_id: "15",
    form_name: "แบบฟอร์มการยืม-คืนของ",
    form_name_en: "Request Inventroy Form",
    url: DOWNLOAD_URL + "/?filename=Form15 Request Inventroy Form.pdf",
  },
  {
    form_id: "16",
    form_name: "แบบคำขอตรวจสอบระบบภายในพื้นที่เช่า",
    form_name_en: "Request for inspection (M&E)",
    url: DOWNLOAD_URL + "/?filename=Form16 Request for inspection (M&E).pdf",
  },
  {
    form_id: "20",
    form_name: "แบบขออนุญาตทำงานในห้องไฟฟ้า หรือสถานีไฟฟ้าย่อย",
    form_name_en: "Permission to work in electrical rooms or Substation",
    url:
      DOWNLOAD_URL +
      "/?filename=Form20 Permission to work in electrical rooms or Substation.PDF",
  },
  {
    form_id: "21",
    form_name:
      "แบบฟอร์มการขอและเชื่อมสัญญาณโทรศัพท์ อินเตอร์เน็ท สัญญาณโทรทัศน์",
    form_name_en: "Telecommunication Request",
    url: DOWNLOAD_URL + "/?filename=Form21 Telecommunication Request.PDF",
  },
  {
    form_id: "23",
    form_name: "แบบฟอร์มขอใช้ระบบปรับอากาศ ระบบดูดอากาศนอกเวลาทำการ",
    form_name_en: "Overtime Air Exhaust System Request",
    url:
      DOWNLOAD_URL +
      "/?filename=Form23 Overtime Air Exhaust System Request.PDF",
  },
];

function WorkPermit() {
  const { language } = useLanguage();

  return (
    <>
      <div className="w-full bg-white">
        <div className="flex flex-wrap pt-4 justify-center">
          <div className="mx-6 p-3 mb-6">
            <div className="flex justify-center">
              <img className="h-[12rem]" src={svg} alt="forms" />
            </div>
            <div className="font-medium">
              <div className="text-center text-xl">
                {language === Language.TH
                  ? "แบบฟอร์มขออนุญาตเข้าทำงานและตกแต่งพื้นที่"
                  : "Work Permit and Construction Requirement"}
              </div>
            </div>
          </div>
        </div>

        <div className="px-20 py-4">
          {formsFiles.map((data, key) => (
            <div className="mt-1">
              <a
                key={key}
                className=""
                href={data.url}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="pr-2"
                  icon="file-lines"
                  // size="2"
                  color="#333"
                />
                {`${language === Language.TH ? "แบบฟอร์ม" : "Form"} ${
                  data.form_id ? data.form_id : "-"
                }`}
                &nbsp;&nbsp;
                {language === Language.TH ? data.form_name : data.form_name_en}
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default WorkPermit;
