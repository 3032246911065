import { ProvideLanguage } from "./providers/language";
import Router from "./routes";

function App() {
  return (
    <ProvideLanguage>
      <Router />
    </ProvideLanguage>
  );
}

export default App;
