import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../../api";
import { useLanguage } from "../../../providers/language";
import { Language } from "../../../schemas/language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Score {
  id: number;
  staff_id: string;
  email: string;
  is_pass: boolean;
  name: string;
  phone_number: string;
  prefix: string;
  score: number;

  time_spend: string;
  time_start: string;
  tenant: {
    id: number;
    tenant_id: string;
    name_th: string;
    name_en: string;
  };
}

interface SummaryScoreProps {
  staffId: number | null;
  examId: number | null;

  gotoNext: () => void;
  gotoExam: (staffId: number, examId: number) => void;
}

function SummaryScore({
  staffId,
  examId,
  gotoNext,
  gotoExam,
}: SummaryScoreProps) {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [score, setScore] = useState<Score | null>(null);

  const [canRetry, setCanRetry] = useState<boolean>(false);

  const handleRetry = () => {
    if (score) {
      api
        .post(`staff/create/`, {
          staff_id: score.staff_id,
          prefix: score.prefix,
          name: score.name,
          email: score.email,
          tenant_id: score.tenant.id,
          phone_number: score.phone_number,
        })
        .then((res) => {
          const { data } = res;
          if (data.data) {
            gotoExam(data.data.id, data.data.exam_result_id);
          }
        });
    }
  };
  const handleSendEmail = () => {
    api
      .get(`examresult/${examId}/certificate`)
      .then((res) => {
        const { data } = res;
        // setScore(data.data);
        navigate("/request-process-2");
      });
  };
  const handleGoHome = () => {
    gotoNext();
  };
  useEffect(() => {
    api
      .get(`staff/${staffId}/examresult/${examId}`)

      .then((res) => {
        const { data } = res;
        setScore(data.data);
        console.log(data.data);
      });
  }, [staffId, examId]);

  useEffect(() => {
    api.get(`staff/${staffId}/verify/`).then((res) => {
      setCanRetry(true);
    });
  }, [staffId]);

  const start_time = score?.time_start ? new Date(score.time_start) : null;

  return (
    <div className="p-4">
      <div className="grid grid-cols-2 mt-8">
        <div className="py-2 px-4 border">
          <span className="font-bold text-sky-800">{"ชื่อ/นามสกุล : "}</span>
          <span className="">{score?.name}</span>
        </div>
        <div className="py-2 px-4 border">
          <span className="font-bold text-sky-800">
            {language === Language.TH
              ? "เลขประจำตัวประชาชน : "
              : "ID Card Number : "}
          </span>
          <span className="">{score?.staff_id}</span>
        </div>
        <div className="py-2 px-4 border">
          <span className="font-bold text-sky-800">
            {language === Language.TH
              ? "ชื่อบริษัท/ร้านค้า : "
              : "Store Name : "}
          </span>
          <span className="">
            {language === Language.TH
              ? score?.tenant.name_th
              : score?.tenant.name_en}
          </span>
        </div>
        <div className="py-2 px-4 border">
          <span className="font-bold text-sky-800">
            {language === Language.TH ? "อีเมล : " : "E-mail : "}
          </span>
          <span className="">{score?.email}</span>
        </div>
      </div>
      <div className="py-4 grid gap-2 mt-4">
        <div className="px-4 pb-2 flex items-center border-b border-neutral-400">
          <div className="w-1/4 font-bold">
            {language === Language.TH ? "คะแนนที่ได้ :" : "Score :"}
          </div>
          <div className="pl-[25%]">{score?.score ? score?.score : 0}</div>
        </div>
        <div className="px-4 pb-2 flex items-center border-b border-neutral-400">
          <div className="w-1/4 font-bold">
            {language === Language.TH ? "ผลการสอบ :" : "Test Result :"}
          </div>
          <div className="pl-[25%]">
            {language === Language.TH
              ? score?.is_pass
                ? "ผ่าน"
                : "ไม่ผ่าน"
              : score?.is_pass
              ? "Pass"
              : "Not Pass"}
          </div>
        </div>
        <div className="px-4 pb-2 flex items-center border-b border-neutral-400">
          <div className="w-1/4 font-bold">
            {language === Language.TH ? "คิดเป็นเปอร์เซ็น :" : "Persentage :"}
          </div>
          <div className="pl-[25%]">
            {score?.score ? `${((score.score / 15) * 100).toFixed(2)}%` : "0%"}
          </div>
        </div>
        <div className="px-4 pb-2 flex items-center border-b border-neutral-400">
          <div className="w-1/4 font-bold">
            {language === Language.TH ? "วันที่ทดสอบ :" : "Test Date :"}
          </div>
          <div className="pl-[25%]">
            {start_time ? formatDate(start_time) : null}
          </div>
        </div>
        <div className="px-4 pb-2 flex items-center border-b border-neutral-400">
          <div className="w-1/4 font-bold">
            {language === Language.TH ? "เวลาที่ทดสอบ :" : "Test Time :"}
          </div>
          <div className="pl-[25%]">
            {language === Language.TH
              ? start_time
                ? formatTime(start_time) + " น."
                : null
              : start_time
              ? formatTime(start_time) + ""
              : null}
          </div>
        </div>
        <div className="px-4 pb-2 flex items-center border-b border-neutral-400">
          <div className="w-1/4 font-bold">
            {language === Language.TH ? "ใช้เวลาทั้งสิ้น :" : "Time Spend :"}
          </div>
          <div className="pl-[25%]">
            {language === Language.TH
              ? score?.time_spend
                ? `${score?.time_spend} นาที`
                : "0 นาที"
              : score?.time_spend
              ? `${score?.time_spend} min`
              : "0 min"}
          </div>
        </div>
      </div>
      <div className="mt-8 gap-4 float-right">
        {!score?.is_pass ? (
          <div className="flex">
            <button
              onClick={canRetry ? handleRetry : undefined}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-8 rounded-[20px]"
              disabled={!canRetry}
            >
              {language === Language.TH ? "ทดสอบอีกครั้ง" : "Retry"}
            </button>
            <div className="pl-4 flex justify-end items-center ">
              {/* <div className="">ถัดไป</div> */}
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-8 rounded-[20px]"
                onClick={handleGoHome}
              >
                {language === Language.TH ? "กลับไปหน้าแรก" : "Back to Home"}
              </button>
              <FontAwesomeIcon
                className=""
                icon="angles-right"
                size="2x"
                color="#333"
              />
            </div>
          </div>
        ) : (
          <div className="flex">
            <button
              onClick={handleSendEmail}
              // className="mr-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-8 rounded-[20px]"
            >
              {language === Language.TH
                ? "ส่งผลการทดสอบไปที่อีเมล์ของคุณ"
                : "Send result to your Email"}
            </button>
            <div className="pl-4 flex justify-end items-center ">
              {/* <div className="">ถัดไป</div> */}
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-8 rounded-[20px]"
                onClick={handleGoHome}
              >
                {language === Language.TH ? "กลับไปหน้าแรก" : "Back to Home"}
              </button>
              <FontAwesomeIcon
                className=""
                icon="angles-right"
                size="2x"
                color="#333"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SummaryScore;

function formatDate(date: Date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
}

function formatTime(date: Date) {
  return [
    date.getHours(),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds()),
  ].join(":");
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}
