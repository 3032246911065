import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguage } from "../../../providers/language";
import { Language } from "../../../schemas/language";

import cardIdSvg from "../../../assets/icons/1-black.svg";

// import cardIdSvg from "../../../assets/icons/icon-12.svg";

const DOWNLOAD_URL = process.env.REACT_APP_DOWNLOAD_API_URL || "";

const formsFiles = [
  {
    form_id: "09",
    form_name: "แบบฟอร์มขอทำบัตรผ่านและบัตรประจำตัวพนักงาน",
    form_name_en: "ID card request form",
    url: DOWNLOAD_URL + "/?filename=Form09 ID card request form.pdf",
  },
  {
    form_id: "15",
    form_name: "แบบฟอร์มขออนุญาตจอดรถค้างคืน",
    form_name_en: "Overnight Parking Request Form",
    url: DOWNLOAD_URL + "/?filename=Form74 Overnight Parking Request Form.pdf",
  },
];

function StaffAndParking() {
  const { language } = useLanguage();

  return (
    <>
      <div className="w-full bg-white">
        <div className="flex flex-wrap pt-4 justify-center">
          <div className="mx-6 p-3 mb-6">
            <div className="flex justify-center">
              <img className="h-[12rem]" src={cardIdSvg} alt="forms" />
            </div>
            <div className="font-medium">
              <div className="text-center text-xl">
                {language === Language.TH
                  ? "แบบฟอร์มบัตรประจำตัวพนักงานและบัตรจอดรถ"
                  : "Staff ID Card and Parking Card"}
              </div>
            </div>
          </div>
        </div>

        <div className="px-20 py-4">
          {formsFiles.map((data, key) => (
            <div className="mt-1">
              <a
                key={key}
                className=""
                href={data.url}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="pr-2"
                  icon="file-lines"
                  // size="2"
                  color="#333"
                />
                {`${language === Language.TH ? "แบบฟอร์ม" : "Form"} ${
                  data.form_id ? data.form_id : "-"
                }`}
                &nbsp;&nbsp;
                {language === Language.TH ? data.form_name : data.form_name_en}
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default StaffAndParking;
